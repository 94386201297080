import React, { useRef, useLayoutEffect, useEffect, useImperativeHandle, forwardRef, useState } from "react";
import { Map, View, Overlay } from "ol";
import { XYZ, Vector as VectorSource, OSM, ImageStatic } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer, Image } from "ol/layer";
import {  Icon, Fill, Text, Style, Circle as CircleStyle, Stroke } from "ol/style";
import { Modify } from 'ol/interaction.js'
import { useTranslation } from 'react-i18next';
import { IoIosClose } from "react-icons/io";
import { Divider, Paper, Stack } from '@mui/material';
import _ from 'lodash';

import { webUrl } from "Shared/utils";
import useLoop from "Shared/Hooks/useLoop";

import { getIconStyle, getLabelStyle, setLayerSource, mapViewConfiguration } from '../../components/utils'
import '../../components/style.css'
import { Projection, transform } from "ol/proj";

const ZoneMap = forwardRef(({ }, ref) => {
  const { t, i18n } = useTranslation()
  
  const mapRef = useRef()
  const divRef = useRef()

  useLayoutEffect(() => {
    const labelSource = new VectorSource({ })

    const labelLayer = new VectorLayer({
      source: labelSource,
      style: function (feature) {
        return new Style({
          text: new Text({
            font: '14px sans-serif',
            text: feature.get("zone_name"),
            fill: new Fill({
              color: "white"
            }),
            backgroundFill: new Fill({
              color: "grey"
            }),
            padding: [3, 3, 3, 3]
          })
        })
      }
    })

    labelLayer.set('name', 'labelLayer')

    const floorplanLayer = new Image({
      source: new ImageStatic({
        url: "",
        projection: new Projection({
          code: 'xkcd-image',
          units: 'pixels',
          extent: [],
        }),
        imageExtent: []
      })
    })

    floorplanLayer.set('name', 'floorplanLayer')

    const view = new View({
      center: mapViewConfiguration["center"],
      extent: mapViewConfiguration["extent"],
      zoom: 0,
      showFullExtent: true
    })

    const map = new Map({
      target: "map",
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
          })
        }),
        floorplanLayer,
        labelLayer
      ],
      view: view
    })

    mapRef.current = {
      "map": map,
      "labelSource": labelSource,
      "labelLayer": labelLayer,
      "floorplanLayer": floorplanLayer,
      "interaction": null
    }
  }, [])

  useImperativeHandle(ref, () => ({
    zoneUpdate: (data) => {
        setLayerSource(
          mapRef.current.labelLayer, 
          {
            "type": "FeatureCollection",
            "features": data.map((i) => ({
              "type": "Feature",
              "id": i["zone_id"],
              "geometry":{
                "type": "Point","coordinates":[i["marker_lon"], i["marker_lat"]]
              },
              "properties":{
                "zone_name": i["zone_name"],
                "zone_description": i["zone_description"],
              }
            }))
          }
        )
    },
    floorPlanUpdate: (blob, coordinate) => {
      const view  = new View({
        center: [(coordinate["x1"]+coordinate["x2"])/2, (coordinate["y1"]+coordinate["y2"])/2],
        extent: [coordinate["x1"]-5, coordinate["y1"]-5 , coordinate["x2"]+5, coordinate["y2"]+5],
        zoom: 0,
        showFullExtent: true
      })

      view.fit([coordinate["x1"]-5, coordinate["y1"]-5 , coordinate["x2"]+5, coordinate["y2"]+5])

      mapRef.current.map.setView(view);

      mapRef.current.floorplanLayer.setSource(new ImageStatic({
        url: URL.createObjectURL(blob),
        projection: new Projection({
          code: 'xkcd-image',
          units: 'pixels',
          extent: [coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]]
        }),
        imageExtent: [coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]]
      }))
    },
    setModifing: (state) => {
      if (state === true) {
        const modify = new Modify({source: mapRef.current.labelSource});

        mapRef.current.map.addInteraction(modify);
        mapRef.current.interaction = modify
      }
      else {
        mapRef.current.map.removeInteraction(mapRef.current.interaction)
      }
    },
    getZones: () => {
      const map = mapRef.current.map
      return map.getLayers().getArray().find(layer => layer.get('name') == 'labelLayer').getSource().getFeatures()
    }
  }))

  return (<>
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} ref={divRef}>
      <div style={{ height: "100%", width: "100%" }} id="map" />
    </div>
  </>)
})

export default ZoneMap
