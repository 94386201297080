import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, Stack } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { Form, Input } from 'antd';
import { FaEdit, FaCheck, FaTimes } from "react-icons/fa";
import { TbArrowsMinimize, TbArrowsMaximize } from "react-icons/tb";
import { transform } from 'ol/proj';

import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import PopupModal from 'Shared/Components/PopupModal';
import { TextInput, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';
import ZoneMap from './components/ZoneMap';
import { IconButtonComponent } from 'Shared/Components/Icon';

const SmartwatchConfinedSpaceZones = () => {
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const mapRef = useRef()

  const [formOpen, setFormOpen] = useState({ "zone": null, "isOpen": false });
  const [zones, setZones] = useState([])
  const [loading, setLoading] = useState(true);
  const [modifing, setModifing] = useState(false);
  const [minimize, setMinimize] = useState(false);

  const tableRef = useRef()

  const filterRef = useRef({
    "search": ""
  })

  const fetchZones = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/zones`,
      params: {
        "search": filterRef.current.search
      },
      returnType: "json"
    })

    setZones(body)

    mapRef.current.zoneUpdate(body)
  }

  const fetchFloorPlan = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/floorplan_meta`,
      returnType: "json"
    })

    if (body.length > 0) {
      const blob = await send({
        method: "GET",
        url: `${apiUrl}/smartwatch/floorplan`,
        returnType: "blob"
      })

      mapRef.current.floorPlanUpdate(blob, body[0])
    }
  }

  const onFinish = async (values) => {
    setLoading(true)

    const res = await send({
      method: "PUT",
      url: `${apiUrl}/smartwatch/zone/${formOpen["zone"]}`,
      contentType: "json",
      body: {
        "zone_name": values["zone_name"],
        "zone_description": values["zone_description"]
      }
    })

    if (res != null) {
      toggleForm()
      showSuccess(t("general_success"))

      tableRef.current.setLoading(true)

      await fetchZones()

      tableRef.current.setLoading(false)
    }

    setLoading(false)
  }

  const toggleForm = (row) => {
    if (!formOpen["isOpen"]) {
      form.setFieldsValue({
        "zone_name": row["zone_name"],
        "zone_description": row["zone_description"]
      })
    }
    else {
      form.resetFields()
    }

    setFormOpen({
      "zone": row ? row["zone_id"] : null,
      "isOpen": !formOpen["isOpen"]
    })
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        fetchZones(),
        fetchFloorPlan()
      ]);

      setLoading(false)
    }

    init()    
  }, [])

  const onSearch = async (value, _e, info) => {    
    filterRef.current.search = value

    tableRef.current.setLoading(true)

    await fetchZones()

    tableRef.current.setLoading(false)
  }

  const submit = async () => {
    const zones = mapRef.current.getZones()

    setLoading(true)

    const res = await send({
      method: "PUT",
      url: `${apiUrl}/smartwatch/zones`,
      contentType: "json",
      body: {
        "zones": JSON.stringify(zones.map((i) => ({
          "zone_id": i.getId(),
          "coordinate": transform(i.getGeometry().getCoordinates(), 'EPSG:3857', 'EPSG:4326')
        })))
      }
    })

    if (res != null) {
      showSuccess(t("general_success"))

      await fetchZones()
    }

    updateModifyState(false)
    setLoading(false)
  }

  const reset = () => {
    mapRef.current.zoneUpdate(zones)

    updateModifyState(false)
  }

  const updateModifyState = (state) => {
    setModifing(state)
    mapRef.current.setModifing(state)
  }
    
  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row style={{ height: minimize ? "100%" : "50%", marginBottom: "5px" }}>
        <Col xs={12} style={{ height: "100%", overflow: "hidden", paddingLeft: 0, paddingRight: 0}}>
          <div style={{ display: "flex", justifyContent: "end", padding: "5px 10px 5px 5px" }}>
            {(modifing == true) ? (
              <Stack direction="row">
                <IconButtonComponent title={t("general_submit")} onClick={() => submit()}>
                  <FaCheck size={20}/>
                </IconButtonComponent>

                <IconButtonComponent title={t("general_reset")} onClick={() => reset()}>
                  <FaTimes size={20}/>
                </IconButtonComponent>
              </Stack>
            ) : (
              <Stack direction="row">
                <IconButtonComponent title={t("general_edit")} onClick={() => updateModifyState(true)}>
                  <FaEdit size={20}/>
                </IconButtonComponent>
              </Stack>
            )}
          </div>

          <ZoneMap ref={mapRef}/>
        </Col>
      </Row>

      {minimize ? (
        <Row style={{ marginTop: "5px", display: minimize ? "block" : "none" }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "end", paddingRight: "10px" }}>
            <IconButtonComponent onClick={() => setMinimize(false)}>
              <TbArrowsMaximize size={15}/>
            </IconButtonComponent>
          </div>
        </Row>
      ) : (
        <Row style={{ height: "50%" }}>
          <Col xs={12} style={{ height: "100%", overflow: "auto", paddingLeft: 0, paddingRight: 0}}>
            <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
              <DataTable
                data={zones} count={null} ref={tableRef} onSearch={onSearch} searchPlaceHolder={t("smartwatch_searchZone")}
                // onRowClick={(e, row) => navigate(`./${row["zone_id"]}`)}
                columns={{
                  "zone_name": { "label": t("smartwatch_zoneName"), "sorting": false, "nowrap": true },
                  "zone_description": { "label": t("smartwatch_zoneDescription"), "sorting": false, "nowrap": true },
                }}
                actions={[
                  {icon: <FaEdit size={20}/>, text: t("general_edit"), onClick: function(row) { toggleForm(row) }},
                ]}
                appActions={[
                  Object.create({icon: <TbArrowsMinimize size={20}/>, text: t("general_add"), onClick: function() { setMinimize(true) }})
                ]}
              />
            </Paper>
          </Col>
        </Row>
      )}
    </Container> 

    <PopupModal isOpen={formOpen["isOpen"]} close toggle={() => toggleForm()} width="80%">
      <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ }} style={{ width: "100%", padding: "10px" }}>      
        <TextInput field_name={t("smartwatch_zoneName")} field_id="zone_name"/>
        <TextInput field_name={t("smartwatch_zoneDescription")} field_id="zone_description"/>

        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default SmartwatchConfinedSpaceZones