import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Menu, Stack, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import Appbar from './components/Appbar';
import DrawerMenu  from './components/DrawerMenu';
import Dashboard from './components/Dashboard';

import LoadingScreen from 'Shared/Components/LoadingScreen';
import useWindowSize from 'Shared/Hooks/useWindowSize';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl, jwtDecode } from 'Shared/utils';

const Main = () => {
  const { t, i18n } = useTranslation()
  const { user } = useAuth0()
  const { organization, project } = useParams()
  const { send } = useFetchData()
  const windowSize = useWindowSize()
  const navigate = useNavigate()

  const drawerWidth = { "xs": 60, "lg": 240 }
  const appbarHeight = 60

  const [projects, setProjects] = useState()
  const [projectInfo, setProjectInfo] = useState()
  const [loading, setLoading] = useState(true);
  const [appbarLoading, setAppbarLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setLoading(true)

      await Promise.all([
        send({ url: `${apiUrl}/common/permission`, method: "GET", returnType: "json" }).then(res =>
          res && setProjects(res)
        )
      ]);

      setLoading(false)
    }

    if (organization == null) {
      navigate(`/portal/${user.org_name}`)
    }

    init()
  }, [])

  useEffect(() => {
    const getProject = async () => {
      await Promise.all([
        send({ url: `${apiUrl}/common/project_info`, method: "GET", returnType: "json", params: { "project": project } }).then(res =>
          res && setProjectInfo(res)
        ),
      ]);
    }

    const init = async () => {
      setLoading(true)

      if (project) {
        await getProject()
      }

      setLoading(false)
    }
    
    init()
  }, [project])

  return (<>
    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
      {organization && <>
        <Box sx={{ height: appbarHeight, zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "white", boxShadow: 1, display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px", overflow: "hidden" }}>
          <Appbar appbarHeight={appbarHeight} projectInfo={projectInfo} projects={projects} setAppbarLoading={setAppbarLoading}/>
        </Box>

        {(project != null && project != "") ? (
          <Box sx={{ display: 'flex'}}>
            <Box sx={{ width: { xs: drawerWidth["xs"], lg: drawerWidth["lg"] }}}>
              <DrawerMenu appbarHeight={appbarHeight} drawerWidth={drawerWidth} projectInfo={projectInfo} />
            </Box>

            <Box component="main" sx={{ width: { xs: windowSize["width"]-drawerWidth["xs"], lg: windowSize["width"]-drawerWidth["lg"] }, height: windowSize["height"]-appbarHeight, overflow: "auto", backgroundColor: "#F2F2F2", padding: "15px" }}>
              <Outlet context={{ projectInfo }}/>
            </Box>
          </Box>
        ) : (
          <Box component="main" sx={{ width: windowSize["width"], height: windowSize["height"]-appbarHeight, overflow: "auto" }}>
            <List style={{ padding: "0px" }}>
              {(projects ?? []).map((i) => 
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText primary={`${i[i18n.language.toLowerCase().substring(0, 2)]}`} onClick={() => navigate(`/portal/${organization}/${i["project_code"]}`)} /> 
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Box>
        )}
      </>}
    </Box>

    <LoadingScreen isOpen={loading || appbarLoading} />
  </>)
}

export default Main