import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { Box, Paper } from '@mui/material';
import { Form } from 'antd';

import useWindowSize from 'Shared/Hooks/useWindowSize'
import useFetchData from 'Shared/Hooks/useFetchData';
import { NotifyToast, showError, showSuccess } from 'Shared/Components/NotifyToast';
import { apiUrl } from 'Shared/utils'
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { TextInput, PasswordInput, customizeRequiredMark } from 'Shared/Components/FormComponents';

const Signup = () => {
  const { t, i18n } = useTranslation()
  const windowSize = useWindowSize()
  const { send } = useFetchData()
  const navigate = useNavigate()
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false)

  const signup = async (values) => {
    if (values["password"] !== values["confirmPassword"]) {
      showError(t("signup_passwordNotMatch"))
      return
    }

    setLoading(true)
    
    const res = await send({
      method: "POST",
      url: `${apiUrl}/common/signup`,
      contentType: "json",
      body: {
        "email": values["email"],
        "displayname": values["name"],
        "password": values["password"],
      }
    })

    if (res != null) {
      alert(t("signup_registrationSuccess"))
      form.resetFields()
      navigate("/")
    }
    else {
      setLoading(false)
    }
  }

  return (<>
    <img src={`${apiUrl}/common/background`} style={{ width: windowSize["width"], height: windowSize["height"], position: "absolute", objectFit: "fill", zIndex: -1 }} />
  
    <Container fluid className='mainContainer' style={{ width: windowSize["width"], height: windowSize["height"], padding: "10px" }}>
      <Paper elevation={3} sx={{ width: { sm: '100%', md: '40%' }, display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "10px", paddingRight: "10px", maxHeight: "90%", margin: "auto", overflow: "auto" }}>
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <img src={`${apiUrl}/common/icon/build4smart`} style={{ height: "50px" }} />
        </Box>

        <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
          <img src={`${apiUrl}/common/favicon/build4smart`} style={{ height: "50px" }} />
        </Box>

        <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={signup} initialValues={{ }} style={{ width: "100%", padding: "10px" }}>
          <TextInput field_name={t("signup_email")} field_id="email" required/>
          <TextInput field_name={t("signup_displayname")} field_id="name" required/>
          <PasswordInput field_name={t("signup_password")} field_id="password" required/>
          <PasswordInput field_name={t("signup_confirmPassword")} field_id="confirmPassword" required/>
        
          <ButtonGroup style={{ width: "100%" }}>
            <Button type="primary" htmlType="submit">
              {t("submit")}
            </Button>
          </ButtonGroup>
        </Form>
      </Paper>
    </Container>

    <div>{<NotifyToast/>}</div>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default Signup