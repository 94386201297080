import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom'
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Menu, Stack, Typography, Paper } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import { HiSignal, HiSignalSlash } from "react-icons/hi2";
import { IoIosAlert } from "react-icons/io";
import { MdOutlinePending } from "react-icons/md";
import { FiCheckCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { MdOutlineTimerOff } from "react-icons/md";

import { IconButtonComponent } from 'Shared/Components/Icon';
import PopupModal from 'Shared/Components/PopupModal';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import useLoop from 'Shared/Hooks/useLoop';
import useWindowSize from 'Shared/Hooks/useWindowSize';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl, webUrl } from 'Shared/utils';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';
import moment from 'moment';

const Dashboard = () => {
  const { user, logout } = useAuth0()
  const { organization, project } = useParams()
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const { projectInfo } = useOutletContext()

  const [loading, setLoading] = useState(true);

  const [reportConfnedSpaceSmartwatch, setReportConfnedSpaceSmartwatch] = useState()
  const [reportOutdoorSmartwatch, setReportOutdoorSmartwatch] = useState()
  const [reportCameras, setReportCameras] = useState()
  const [reportPtw, setReportPtw] = useState()
  const [reportAlert, setReporAlert] = useState()

  const [activeIndex, setActiveIndex] = useState()
  const [projectModules, setProjectModules] = useState()
  const [alertExist, setAlertExist] = useState(false)

  const getReportConfnedSpaceSmartwatch = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/report/confined_space`,
      returnType: "json"
    })

    setReportConfnedSpaceSmartwatch(body)
  }

  const getReportOutdoorSmartwatch = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/report/outdoor`,
      returnType: "json"
    })

    setReportOutdoorSmartwatch(body)
  }

  const getReportCameras = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/camera/report`,
      returnType: "json"
    })

    setReportCameras(body)
  }

  const getReportPtw = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/permit_to_work/report`,
      returnType: "json"
    })

    setReportPtw(body)
  }

  const getReportAlert = async (modules) => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/common/alert/report`,
      returnType: "json"
    })

    const getCount = (type, ack) => {
      const item = body.filter((i) => i["type"] === type && i["ack"] === ack)

      if (item.length === 0) {
        return 0
      }

      if (alertExist === false) {
        setAlertExist(true)
      }

      return item[0]["count"]
    }

    const data = []

    if (modules.includes("outdoor_smartwatch") || modules.includes("confined_space_smartwatch")) {
      data.push({ "name": t("alert_smartwatchAck"), "value": getCount("smartwatch", true) })
      data.push({ "name": t("alert_smartwatchNoAck"), "value": getCount("smartwatch", false) })
    }

    if (modules.includes("ai_detection")) {
      data.push({ "name": t("aiDetectionAck"), "value": getCount("ai_detection", true) })
      data.push({ "name": t("aiDetectionNoAck"), "value": getCount("ai_detection", false) })
    }

    if (modules.includes("proximity")) {
      data.push({ "name": t("proximityAck"), "value": getCount("proximity", true) })
      data.push({ "name": t("proximityNoAck"), "value": getCount("proximity", false) })
    }

    setReporAlert(data)
  }

  useEffect(() => {
    const init = async (modules) => {
      setLoading(true)

      setAlertExist(false)
      setProjectModules(modules)
      setReportConfnedSpaceSmartwatch()
      setReportOutdoorSmartwatch()
      setReportCameras()
      setReportPtw()

      await Promise.all([
        getReportAlert(modules),
        modules.includes("confined_space_smartwatch") && getReportConfnedSpaceSmartwatch(),
        modules.includes("outdoor_smartwatch") && getReportOutdoorSmartwatch(),
        modules.includes("cameras") && getReportCameras(),
        modules.includes("permit_to_work") && getReportPtw()
      ]);

      setLoading(false)
    }

    if (projectInfo != null) {
      const modules = projectInfo["modules"].filter((i) => i["enabled"] === 1).map((i) => i["module"])

      init(modules)
    }
  }, [projectInfo])

  return (<>
    <div className="mainContainer">
      <div style={{ width: "100%", height: "100%", overflow: "auto", scrollbarWidth: 'none' }}>
        {projectInfo &&
          <Stack direction="column" spacing={2}>
            {reportAlert && 
              <Paper elevation={3} style={{ padding: "10px", width: "100%", height: alertExist ? "300px" : "70px" }}>
                <div>{t("alert_alertSummary")}: {moment().format("YYYY-MM-DD")}</div>
                {alertExist ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart width="100%" height="100%">
                      <Pie
                        dataKey="value"
                        isAnimationActive={true}
                        data={reportAlert}
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        fill="#8884d8"
                      >
                        {(projectModules.includes("outdoor_smartwatch") || projectModules.includes("confined_space_smartwatch")) && <>
                          <Cell key="smartwatch_ack" fill="#FF9999" />
                          <Cell key="smartwatch_noAck" fill="#FF0000" />
                        </>}

                        {projectModules.includes("cameras") && <>
                          <Cell key="ai_detection_ack" fill="#99FF99" />
                          <Cell key="ai_detection_anoAck" fill="#00FF00" />
                        </>}
                        
                        {projectModules.includes("proximity") && <>
                          <Cell key="proximity_ack" fill="#9999FF" />
                          <Cell key="proximity_noAck" fill="#0000FF" />
                        </>}
                      </Pie>

                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" , justifyContent: "center"}}>
                    <div>{t("alert_noRecord")}</div>
                  </div>
                )}
              </Paper>
            }
          
            {reportCameras &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_cameras")}</div>
                <Stack direction="row" spacing={2}>
                  <div style={{ display: "flex", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "#66ff99", padding: "10px" }}>
                    <HiSignal size={30} style={{ color: "green" }}/>
                    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div>{t("cameras_online")}</div>
                      <div>{reportCameras["online"]}</div>
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "#bfbfbf", padding: "10px" }}>
                    <HiSignalSlash size={30} style={{ color: "grey" }}/>
                    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <div>{t("cameras_offline")}</div>
                      <div>{reportCameras["offline"]}</div>
                    </div>
                  </div>
                </Stack>
              </Paper>
            }

            {reportConfnedSpaceSmartwatch &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_confinedSpaceSmartwatch")}</div>
                <Stack direction="row" spacing={2}>
                  <div style={{ display: "flex", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "#66ff99", padding: "10px" }}>
                    <HiSignal size={30} style={{ color: "green" }}/>
                    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <div>{t("smartwatch_online")}</div>
                      <div>{reportConfnedSpaceSmartwatch["online"]}</div>
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "#bfbfbf", padding: "10px" }}>
                    <HiSignalSlash size={30} style={{ color: "grey" }}/>
                    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <div>{t("smartwatch_offline")}</div>
                      <div>{reportConfnedSpaceSmartwatch["offline"]}</div>
                    </div>
                  </div>

                  {/* <div style={{ display: "flex", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "pink", padding: "10px" }}>
                    <IoIosAlert size={30} style={{ color: "red" }}/>
                    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <div>{reportConfnedSpaceSmartwatch["alert"]}</div>
                    </div>
                  </div> */}
                </Stack>
              </Paper>
            }

            {reportOutdoorSmartwatch &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_outdoorSmartwatch")}</div>
                <Stack direction="row" spacing={2}>
                  <div style={{ display: "flex", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "#66ff99", padding: "10px" }}>
                    <HiSignal size={30} style={{ color: "green" }}/>
                    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <div>{t("smartwatch_online")}</div>
                      <div>{reportOutdoorSmartwatch["online"]}</div>
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "#bfbfbf", padding: "10px" }}>
                    <HiSignalSlash size={30} style={{ color: "grey" }}/>
                    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <div>{t("smartwatch_offline")}</div>
                      <div>{reportOutdoorSmartwatch["offline"]}</div>
                    </div>
                  </div>

                  {/* <div style={{ display: "flex", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "pink", padding: "10px" }}>
                    <IoIosAlert size={30} style={{ color: "red" }}/>
                    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <div>{reportOutdoorSmartwatch["alert"]}</div>
                    </div>
                  </div> */}
                </Stack>
              </Paper>
            }

            {reportPtw &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_permitToWork")}</div>
                <Stack direction="row" spacing={2}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "#bfbfbf", padding: "10px" }}>
                    <MdOutlinePending size={30} style={{ color: "grey" }}/>
                    <div>{reportPtw["pending"]}</div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "#66ff99", padding: "10px" }}>
                    <FiCheckCircle size={30} style={{ color: "green" }}/>
                    <div>{reportPtw["approved"]}</div>
                  </div>
                  
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "pink", padding: "10px" }}>
                    <MdOutlineTimerOff size={30} style={{ color: "red" }}/>
                    <div>{reportPtw["expired"]}</div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px", width: "130px", borderRadius: "5px", backgroundColor: "pink", padding: "10px" }}>
                    <IoClose size={30} style={{ color: "red" }}/>
                    <div>{reportPtw["rejected"]}</div>
                  </div>
                </Stack>
              </Paper>
            }
          </Stack>
        }
      </div>
    </div>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default Dashboard


