import React, { useRef, useLayoutEffect, useEffect, useImperativeHandle, forwardRef, useState } from "react";
import { Map, View, Overlay } from "ol";
import { XYZ, Vector as VectorSource, OSM, ImageStatic } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer, Image } from "ol/layer";
import {  Icon, Fill, Text, Style, Circle as CircleStyle, Stroke } from "ol/style";
import { useTranslation } from 'react-i18next';
import { IoIosClose } from "react-icons/io";
import { Divider, Paper, Stack } from '@mui/material';
import _ from 'lodash';

import { webUrl } from "Shared/utils";
import useLoop from "Shared/Hooks/useLoop";

import { getIconStyle, getLabelStyle, setLayerSource, mapViewConfiguration } from '../../components/utils'
import '../../components/style.css'
import { Projection, transform } from "ol/proj";

const FloorplanDisplay = forwardRef(({ }, ref) => {
  const { t, i18n } = useTranslation()
  
  const [dimension, setDimension] = useState({ "width": 0, "height": 0 })
  const [opened, setOpened] = useState({})
  const mapRef = useRef()
  const divRef = useRef()

  const show = (feature) => {    
    setOpened({
      "zone_id": feature["zone_id"],
      "zone_name": feature["zone_name"],
      "zone_description": feature["zone_description"],
      "workers": feature["workers"]
    })
  }

  useEffect(() => {
    const checkSize = () => {
      setDimension({
        "width": divRef.current.offsetWidth,
        "height": divRef.current.offsetHeight
      })
    };

    if (!divRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      checkSize()
    });

    resizeObserver.observe(divRef.current);
    
    return () => resizeObserver.disconnect();
  }, []);

  useLayoutEffect(() => {
    const labelLayer = new VectorLayer({
      source: new VectorSource({ }),
      style: function (feature) {
        return new Style({
          text: new Text({
            font: '14px sans-serif',
            text: feature.get("label"),
            fill: new Fill({
              color: "white"
            }),
            backgroundFill: new Fill({
              color: "grey"
            }),
            padding: [3, 3, 3, 3]
          })
        })
      }
    })

    labelLayer.set('name', 'labelLayer')

    const floorplanLayer = new Image({
      source: new ImageStatic({
        url: "",
        projection: new Projection({
          code: 'xkcd-image',
          units: 'pixels',
          extent: [],
        }),
        imageExtent: []
      })
    })

    floorplanLayer.set('name', 'floorplanLayer')

    const view = new View({
      center: mapViewConfiguration["center"],
      extent: mapViewConfiguration["extent"],
      zoom: 0,
      showFullExtent: true
    })

    const map = new Map({
      target: "map",
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
          })
        }),
        floorplanLayer,
        labelLayer
      ],
      view: view
    })

    map.on('click', function (e) {
      const feature = map.forEachFeatureAtPixel(e.pixel, function (f) {
        if (f.getId() == null) {
          return null
        }

        return {
          "zone_id": f.getId(),
          "zone_name": f.get("zone_name"),
          "zone_description": f.get("zone_description"),
          "workers": f.get("workers")
        }
      } , {
        layerFilter: function (layer) {
          return layer.get('name') === 'labelLayer'
        }
      })

      if (feature != null) {
        show(feature)
      }
    })

    mapRef.current = {
      "map": map,
      "labelLayer": labelLayer,
      "floorplanLayer": floorplanLayer
    }
  }, [])

  useImperativeHandle(ref, () => ({
    zoneUpdate: (data) => {
      setLayerSource(
        mapRef.current.labelLayer, 
        {
          "type": "FeatureCollection",
          "features": data.map((i) => ({
            "type": "Feature",
            "id": i["zone_id"],
            "geometry":{
              "type": "Point","coordinates":[i["marker_lon"], i["marker_lat"]]
            },
            "properties":{
              "zone_name": i["zone_name"],
              "zone_description": i["zone_description"],
              "workers": i["workers"],
              "label": `${i["zone_name"]} [${i["workers"].length}]`
            }
          }))
        }
      )

      if (opened["zone_id"]) {        
        setOpened({
          "zone_id": opened["zone_id"],
          "zone_name": opened["zone_name"],
          "zone_description": opened["zone_description"],
          "workers": data.filter((i) => i["zone_id"] == opened["zone_id"])[0]["workers"]
        })
      }
    },
    floorPlanUpdate: (blob, coordinate) => {
      const view  = new View({
        center: [(coordinate["x1"]+coordinate["x2"])/2, (coordinate["y1"]+coordinate["y2"])/2],
        extent: [coordinate["x1"]-5, coordinate["y1"]-5 , coordinate["x2"]+5, coordinate["y2"]+5],
        zoom: 0,
        showFullExtent: true
      })

      view.fit([coordinate["x1"]-5, coordinate["y1"]-5 , coordinate["x2"]+5, coordinate["y2"]+5])

      mapRef.current.map.setView(view);

      mapRef.current.floorplanLayer.setSource(new ImageStatic({
        url: URL.createObjectURL(blob),
        projection: new Projection({
          code: 'xkcd-image',
          units: 'pixels',
          extent: [coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]]
        }),
        imageExtent: [coordinate["x1"], coordinate["y1"] , coordinate["x2"], coordinate["y2"]]
      }))
    }
  }))

  return (<>
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} ref={divRef}>
      <div style={{ height: "100%", width: "100%" }} id="map" />
      {(opened["zone_id"] != null) && 
        <div style={{ height: dimension["height"]*0.8, width: dimension["width"]*0.4, position: "absolute", right: 30, marginTop: dimension["height"]*0.1 }}>
          <Paper elevation={3} style={{ height: "100%", width: "100%", padding: "10px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <IoIosClose onClick={() => setOpened({})}/>
            </div>

            <Stack spacing={2} style={{ overflow: "auto" }}>
              <div>
                <div style={{ fontSize: "12px" }}>{t("smartwatch_zoneName")}: {opened["zone_name"]}</div>
                <div style={{ fontSize: "12px" }}>{t("smartwatch_zoneDescription")}: {opened["zone_description"]}</div>
              </div>

              {(opened["workers"].length > 0) && <>
                <Divider />

                <div>
                  <Stack spacing={2}  divider={<Divider orientation="orizontal" flexItem />}>
                    {opened["workers"].map((worker) => <div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_worker")}: {worker["worker_name"]}</div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_betteryLevel")}: {worker["battery_level"]}</div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_bodyTemp")}: {worker["body_temp"]}</div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_heartRate")}: {worker["heart_rate"]}</div>
                      <div style={{ width: "fit-content" }}>{worker["alerts"]}</div>
                    </div>)}
                  </Stack>
                </div>
              </>}
            </Stack>
          </Paper>
        </div>
      }
    </div>
  </>)
})

export default FloorplanDisplay
