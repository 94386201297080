import React, { forwardRef, useState, useImperativeHandle, useRef } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, Stack, CircularProgress, Box, TableSortLabel, Checkbox, Divider } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { Input, Select } from 'antd';

import { IconButtonComponent } from './Icon';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		fontSize: 14,
		fontWeight: 'bold'
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const DataTable = forwardRef(({ columns, data, count, actions, loadData, appActions, selectActions, onRowClick, showCheck, selectState, onSelect, onSelectAll, onSearch, searchPlaceHolder }, ref) => {
  const tableStateRef = useRef({
    "order": true,
    "orderBy": "",
    "page": 1,
    "itemsPerPage": 20
  })

  // {Object.values(selectState).includes(true)

	const [tableState, setTableState] = useState(tableStateRef.current)
	const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => ({
		getState: () => {
			return tableStateRef.current
		},
    setLoading: (value) => {
			setLoading(value)
		},
		setPage: (page) => {
			tableStateRef.current.page = page
      setTableState(tableStateRef.current)
		}
	}))

	const sort = (id) => {
		if (tableStateRef.current.orderBy === id) {
      tableStateRef.current.order = !tableStateRef.current.order
		}
		else {
			tableStateRef.current.order = true
			tableStateRef.current.orderBy = id
		}

    setTableState(tableStateRef.current)

		loadData()
	}

  const handlePageChange = (_, newPage) =>{
		tableStateRef.current.page = newPage
		setTableState(tableStateRef.current)
		
		loadData()
	}

  const pageSizeChange = (value) => {
    tableStateRef.current.itemsPerPage = value
    tableStateRef.current.page = 1
    setTableState(tableStateRef.current)

    loadData()
  }

  return (<>
    {(loading || data == null) ? (
      <Box className="center">
        <CircularProgress />
      </Box>
    ) : (<>
      {(onSearch != null || appActions != null || selectActions != null) && 
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop: "10px" }}>
          <div>
            {onSearch &&
              <div style={{ marginLeft: "10px" }}>
                <Input.Search
                  placeholder={searchPlaceHolder}
                  allowClear
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </div>
            }
          </div>

          <div style={{ display: "flex", flexDirection: "row", paddingRight: "20px" }}>
            <Stack direction="row" spacing={3}>
              {selectState && <>
                {(Object.values(selectState).includes(true) && selectActions) && <>
                  {selectActions.map((action) => 
                    <IconButtonComponent title={action.text} onClick={() => action.onClick()}>
                      {action.icon}
                    </IconButtonComponent>
                  )}
                </>}
              </>}

              {appActions && <>
                {appActions.map((action) => 
                  <IconButtonComponent title={action.text} onClick={() => action.onClick()}>
                    {action.icon}
                  </IconButtonComponent>
                )}
              </>}
            </Stack>
          </div>
        </div>
      }
      
      <TableContainer sx={{ flex: 1, overflow: "auto"  }} className="scollbar">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {showCheck && 
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    onChange={(e) => onSelectAll(e.target.checked)}
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={data.length > 0 && !Object.values(selectState).includes(false)}
                  />
                </TableCell>
              }
              
              {Object.entries(columns).map((i) => <> 
                {i[1]["sorting"] ? (
                  <StyledTableCell sx={{ minWidth: i[1]["minWidth"] }} align="center" key={i[0]} sortDirection={tableState.orderBy === i[0] ? (tableState.order ? 'asc' : 'desc') : false}>
                    <TableSortLabel active={tableState.orderBy === i[0]} direction={tableState.order ? 'asc' : 'desc'} onClick={() => sort(i[0])}>
                      <div style={{ overflow: "hidden", whiteSpace: "nowrap"}}>{i[1]["label"]}</div>
                      
                      {tableState.orderBy === i[0] ? (
                        <Box component="span" sx={visuallyHidden}>
                          {tableState.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell sx={{ minWidth: i[1]["minWidth"] }} align="center">
                    <div style={{ overflow: "hidden", whiteSpace: "nowrap"}}>{i[1]["label"]}</div>      
                  </StyledTableCell>
                )}
              </>)}

              {actions && 
                <StyledTableCell align="center">{''}</StyledTableCell>
              }
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, i) => (<>	
              <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: onRowClick ? "pointer": "auto" }} hover={onRowClick != null} onClick={(e) => onRowClick && onRowClick(e, row)}>
                {showCheck && 
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      onChange={(e) => onSelect(row.id, e.target.checked)}
                      checked={selectState[row.id]}
                    />
                  </TableCell>
                }
            
                {Object.entries(columns).map((j) =>
                  <StyledTableCell align="center" key={j[0]}>
                    {j[1]["nowrap"] ? (
                      <div style={{ overflow: "hidden", whiteSpace: "nowrap"}}>{(row[j[0]] !== "" && row[j[0]] != null) ? row[j[0]] : "-"}</div>
                    ) : (
                      <div>{(row[j[0]] !== "" && row[j[0]] != null) ? row[j[0]] : "-"}</div>
                    )}
                  </StyledTableCell>
                )}

                {actions &&
                  <StyledTableCell align="center" sx={{width : `${actions.length*50}px`}} key="actionBtn">
                    <Stack justifyContent="center" direction="row" spacing={0} >
                      {actions.map((action) => 
                        <IconButtonComponent title={action.text} onClick={() => action.onClick(row)}>
                          {action.icon}
                        </IconButtonComponent>
                      )}
                    </Stack>
                  </StyledTableCell>
                } 
              </TableRow>
            </>))}
          </TableBody>
        </Table>
      </TableContainer>
    </>)}

    {(loading == false) && <>
      {(count != null && count > 0) && <>
        <Divider sx={{ bgcolor: "secondary.light" }}/>

        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingLeft: "15px", paddingRight: "15px", paddingTop: "25px", paddingBottom: "25px", height: "40px" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div>Rows per Page: </div>

            <div style={{ marginLeft: "10px" }}>
              <Select
                defaultValue={tableState["itemsPerPage"]}
                style={{ width: 70 }}
                onChange={(e) => pageSizeChange(e)}
                options={[
                  { value: 20, label: '20' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' }
                ]}
              />
            </div>
          </div>
          
          <Pagination size="small" page={tableState["page"]} count={count} siblingCount={2} boundaryCount={0} onChange={handlePageChange}/>
        </div>
      </>}
    </>}
  </>)
})

export default DataTable
