import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { Container, Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { Paper, Stack } from '@mui/material';
import { IoIosArrowBack } from "react-icons/io";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { GoGraph } from "react-icons/go";

import moment from 'moment'
import { saveAs } from 'file-saver'

import { DropdownComponent } from 'Shared/Components/DropdownComponent';
import DatePickerComponent from 'Shared/Components/DatePickerComponent'
import TimePickerComponent from 'Shared/Components/TimePickerComponent'
import LoadingScreen from 'Shared/Components/LoadingScreen';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';
import TimeSeriesPlot from 'Shared/Components/TimeSeriesPlot';
import DataTable from 'Shared/Components/DataTable';

const SmartwatchConfinedSpaceHistory = () => {
  const routeParam = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { send } = useFetchData()

  const [workers, setWorkers] = useState([])
  const [workerSelected, setWorkerSelected] = useState()

  const [data, setData] = useState()

  const [date, setDate] = useState()
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [plotRange, setPlotRange] = useState({
    "start": 0,
    "end": 24
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const init = async () => {
      setLoading(false)
    }

    init()
  }, [])

  const loadData = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/history/confined_space`,
      params: {
        "worker": workerSelected,
        "start_time": moment(`${moment(date).format("YYYY-MM-DD")} ${moment(startTime).format("HH:mm:ss")}`).unix(),
        "end_time": moment(`${moment(date).format("YYYY-MM-DD")} ${moment(endTime).format("HH:mm:ss")}`).unix()
      },
      returnType: "json"
    })

    const d = []

    const z = []

    var z_start = null
    var z_end = null
    var z_zone = null

    if (body.length > 0) {
      body.forEach((i) => {
        if (z_zone == null) {
          z_start = moment(i["updated_at"]).format("YYYY-MM-DD HH:mm:ss")
          z_end = moment(i["updated_at"]).format("YYYY-MM-DD HH:mm:ss")
          z_zone = i["zone_name"]
        }
        else {
          if (z_zone == i["zone_name"]) {
            z_end = moment(i["updated_at"]).format("YYYY-MM-DD HH:mm:ss")
          }
          else {
            z.push({
              "zone": z_zone,
              "start": z_start,
              "end": z_end
            })
  
            z_start = moment(i["updated_at"]).format("YYYY-MM-DD HH:mm:ss")
            z_end = moment(i["updated_at"]).format("YYYY-MM-DD HH:mm:ss")
            z_zone = i["zone_name"]
          }
        }
  
        d.push({
          "time": new Date(moment(i["updated_at"])),
          "body_temp": i["body_temp"],
          "heart_rate": i["heart_rate"]
        })
      });
  
      z.push({
        "zone": z_zone,
        "start": z_start,
        "end": z_end
      })
    
      setData({
        "data": d,
        "zone": z
      })
    }
    else {
      setData({
        "data": [],
        "zone": []
      })
    }
  }

  const onDateChange = async (date) => {
    setDate(date)

    setLoading(true)

    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/worker`,
      params: {
        "date": moment(date).format("YYYY-MM-DD")
      },
      returnType: "json"
    })

    body && setWorkers(body.map((i) => [
      i["worker_id"],
      i["worker_name"]
    ]))

    setLoading(false)
  }

  const update = async () => {
    if (workerSelected && date && startTime && endTime) {
      setLoading(true)

      await loadData()

      const start = parseInt(moment(startTime).startOf('hour').format('HH'))

      var end = moment(endTime)
      if (end.format("mm") !== "00") { end = end.add(1, "hours").startOf('hours') }
      end = end.format('HH')

      setPlotRange({
        "start": start,
        "end": end != "00" ? parseInt(end) : 24
      })

      setLoading(false)
    }
  } 

  return (<>
    <div className="mainContainer"  style={{ borderRadius: "15px", backgroundColor: "white", padding: "20px" }}>
      <Container fluid style={{ height: "100%", overflow: "auto" }} className="scollbar">
        <Row className="mt-1 mb-1">
          <Col xs={12} lg={4} className="mb-1 mb-lg-0">
            <DatePickerComponent date={date} max={new Date()} type="day" onChange={(d) => onDateChange(d)}/>
          </Col>

          <Col xs={12} lg={4} className="mt-1 mb-1 mt-lg-0 mb-lg-0">
            <DropdownComponent value={workerSelected} items={workers} onChange={(v) => setWorkerSelected(v)}/>
          </Col>

          <Col xs={12} lg={4} className="mt-1 mt-lg-0">
            <Stack direction="row"  style={{ height: "100%" }}>
              <div style={{ width: "47%" }}><TimePickerComponent value={startTime} onChange={(d) => setStartTime(d)}/></div>
              <div style={{ height: "100%", width: "6%", display: "flex", justifyContent: "center", alignItems: "center" }}>~</div>
              <div style={{ width: "47%" }}><TimePickerComponent value={endTime} onChange={(d) => setEndTime(d)}/></div>
            </Stack>
          </Col>

          <Col xs={12} className="mt-2" onClick={() => update()}>
            <ButtonGroup style={{ width: "100%" }}>
              <Button>{t("general_search")}</Button>
            </ButtonGroup>
          </Col>
        </Row>

        {data && 
          <Row style={{ marginTop: "20px" }}>
            <Col xs={12} style={{ height: "100%", padding: "10px" }}>
              <Stack spacing={3}>
                <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {t("smartwatch_bodyTemp")}
                  </div>

                  <div style={{ flexGrow: 1, margin: "5px" }} >
                    <TimeSeriesPlot
                      data={data["data"] ?? []}
                      items={[
                        { "axis": "left", "dataKey":"body_temp", "name": t("smartwatch_bodyTemp"), "stroke": "#8884d8" },
                      ]}
                      day={moment(date).format("YYYY-MM-DD")}
                      hour={[plotRange["start"], plotRange["end"]]}
                      yLeft={{ "range": [34, 40], "label": "" }}
                    />
                  </div>
                </div>

                <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {t("smartwatch_heartRate")}
                  </div>
                  
                  <div style={{ flexGrow: 1, margin: "5px" }} >
                    <TimeSeriesPlot
                      data={data["data"] ?? []}
                      items={[
                        { "axis": "left", "dataKey":"heart_rate", "name": t("smartwatch_heartRate"), "stroke": "#8884d8" },
                      ]}
                      day={moment(date).format("YYYY-MM-DD")}
                      hour={[plotRange["start"], plotRange["end"]]}
                      yLeft={{ "range": [34, 40], "label": "" }}
                    />
                  </div>
                </div>

                <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {t("smartwatch_zone")}
                  </div>

                  <Paper elevation={0} sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
                    <DataTable
                      data={data["zone"]} count={null}
                      columns={{
                        "zone": { "label": t("smartwatch_zone"), "sorting": false, "nowrap": true },
                        "start": { "label": t("smartwatch_startTime"), "sorting": false, "nowrap": true },
                        "end": { "label": t("smartwatch_endTime"), "sorting": false, "nowrap": true },
                      }}
                    />
                  </Paper>
                </div>
              </Stack>
            </Col>
          </Row>
        }
      </Container> 
    </div>

    <LoadingScreen isOpen={loading} text={t("general_loading")}/>
  </>)
}

export default SmartwatchConfinedSpaceHistory