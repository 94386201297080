import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'

import useFetchData from 'Shared/Hooks/useFetchData';
import DataTable from 'Shared/Components/DataTable';
import { apiUrl } from 'Shared/utils';
import LoadingScreen from 'Shared/Components/LoadingScreen';

const GasData = () => {
    const { t, i18n } = useTranslation()
    const { organization, project } = useParams()
    const { send } = useFetchData()

    const [gasData, setGasData] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const getLatestData = async () => {
        const body = await send({
          method: "GET",
          url: `${apiUrl}/gas/data`,
          returnType: "json"
        })

        body && setGasData(body)
      }

      const init = async () => {
        await Promise.all([
          getLatestData()
        ]);

        setLoading(false)
      }

      init()
    }, [])
    
    return (<>
      <Container fluid className="mainContainer">
        <Row style={{ height: "100%" }}>
          <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
            <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
              <DataTable
                data={gasData} count={null}
                // onRowClick={() => console.log("Clicked")}
                columns={{
                  "device_name": { "label": t("gas_name"), "sorting": false, "nowrap": true },
                  "h2s": { "label": t("gas_h2s"), "sorting": false, "nowrap": true },
                  "co": { "label": t("gas_co"), "sorting": false, "nowrap": true },
                  "o2": { "label": t("gas_o2"), "sorting": false, "nowrap": true },
                  "ch4": { "label": t("gas_ch4"), "sorting": false, "nowrap": true },
                  "pm25": { "label": t("gas_pm25"), "sorting": false, "nowrap": true },
                  "temperature": { "label": t("gas_temperature"), "sorting": false, "nowrap": true },
                  "combustible_gas": { "label": t("gas_combustibleGas"), "sorting": false, "nowrap": true },
                }}
              />
            </Paper>
          </Col>
        </Row>
      </Container>

      <LoadingScreen isOpen={loading} />
    </>)
}

export default GasData