import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography, Stack } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { Form, Input } from 'antd';
import { MdCenterFocusStrong } from 'react-icons/md';
import { FaEdit } from "react-icons/fa";

import MapDisplay from '../components/MapDisplay';
import AlertIcons from '../components/AlertIcons';

import PopupModal from 'Shared/Components/PopupModal'
import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { SelectField, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import useLoop from 'Shared/Hooks/useLoop';
import { apiUrl } from 'Shared/utils';


const SmartwatchOutdoor = () => {
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const [form] = Form.useForm();

  const [data, setData] = useState([])
  const [workers, setWorkers] = useState([])

  const [loading, setLoading] = useState(true);
  const [formOpen, setFormOpen] = useState({ "device": null, "isOpen": false });

  const mapRef = useRef(null);
  const tableRef = useRef()

  const filterRef = useRef({
    "search": ""
  })

  const loadData = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/latest/outdoor`,
      params: {
        "search": filterRef.current.search
      },
      returnType: "json"
    })

    if (body) {
      setData(
        body.features.map((i) => ({
          "device_id": i["properties"]["device_id"],
          "worker_id": i["properties"]["worker_id"],
          "worker_name": i["properties"]["worker_name"],
          "battery_level": i["properties"]["battery_level"],
          "body_temp": i["properties"]["body_temp"],
          "heart_rate": i["properties"]["heart_rate"],
          "sos": i["properties"]["sos"],
          "online": i["properties"]["online"] ? "Yes": "No",
          "alerts": <AlertIcons data={i["properties"]} />,
        }))
      )

      mapRef.current.updateData(body)
    }
  }

  const focus = (id, zoom) => {
    const feature = mapRef.current.getFeature(id)

    const content = `
      <div style="font-size: 0.8em">
        ${t("smartwatch_worker")}: ${feature.get("worker_name") ? feature.get("worker_name") : "-"}<br />
        ${t("smartwatch_betteryLevel")}: ${feature.get("battery_level") ? feature.get("battery_level") : "-"}<br />
        ${t("smartwatch_bodyTemp")}: ${feature.get("body_temp") ? feature.get("body_temp") : "-"}<br />
        ${t("smartwatch_heartRate")}: ${feature.get("heart_rate") ? feature.get("heart_rate") : "-"}<br />
      </div>
    `

    mapRef.current.zoomToFeature(id, feature, content, zoom)
  }

  const onFinish = async (values) => {
    setLoading(true)

    const res = await send({
      method: "PUT",
      url: `${apiUrl}/smartwatch/assignment`,
      contentType: "json",
      body: {
        "device_id": formOpen["device"],
        "worker_id": values["worker_id"]
      }
    })

    if (res != null) {
      toggleForm()
      showSuccess(t("general_success"))
      loadData()
    }

    setLoading(false)
  }

  const getWorkers = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/common/workers`,
      returnType: "json"
    })

    body && setWorkers(body)
  }

  const toggleForm = (row) => {
    if (formOpen["isOpen"]) {
      form.resetFields()
    }
    else{
      getWorkers()
    }
    
    setFormOpen({
      "device": row,
      "isOpen": !formOpen["isOpen"]
    })
  }

  useEffect(() => {
    const init = async () => {
      await loadData()

      setLoading(false)
    }

    if (!loading) {
      mapRef.current.closePopup()
      setLoading(true)
    }

    init()
  }, [i18n.language])

  useLoop(loadData, 10000)

  const onSearch = async (value, _e, info) => {    
    filterRef.current.search = value

    tableRef.current.setLoading(true)

    await loadData()

    tableRef.current.setLoading(false)
  }

  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row style={{ height: "50%", marginBottom: "5px" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <MapDisplay focus={focus} ref={mapRef}/>
          </Paper>
        </Col>
      </Row>

      <Row style={{ height: "50%", marginTop: "5px" }}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0 }}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={data} count={null} ref={tableRef} onSearch={onSearch} searchPlaceHolder={t("smartwatch_searchWorker")}
              // onRowClick={() => console.log("Clicked")}
              columns={{
                "worker_name": { "label": t("smartwatch_worker"), "sorting": false, "nowrap": true },
                "battery_level": { "label": t("smartwatch_betteryLevel"), "sorting": false, "nowrap": true },
                "body_temp": { "label": t("smartwatch_bodyTemp"), "sorting": false, "nowrap": true },
                "heart_rate": { "label": t("smartwatch_heartRate"), "sorting": false, "nowrap": true },
                "online": { "label": t("smartwatch_online"), "sorting": false, "nowrap": true },
                "alerts": { "label": t("smartwatch_alerts"), "sorting": false, "nowrap": true }
              }}
              actions={[
                // {icon: <FaEdit size={20}/>, text: "Edit", onClick: function(row) { toggleForm(row["device_id"]) }},
                {icon: <MdCenterFocusStrong size={20}/>, text: "Locate Worker", onClick: function(row) { focus(row["device_id"], true) }},
              ]}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    {/* <PopupModal isOpen={formOpen["isOpen"]} close toggle={() => toggleForm()} width="80%">
      <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ "worker_name": "" }} style={{ width: "100%", padding: "10px" }}>      
        <SelectField field_name={t("smartwatch_worker")} field_id="worker_id" props={{ "type": "dropdown", "options": workers.map((i) => ({ "value": i["worker_id"], "label": i["worker_name"] })) }}/>

        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal> */}

    <LoadingScreen isOpen={loading} />
  </>)
}

export default SmartwatchOutdoor