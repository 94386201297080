import React from 'react'
import { Form, InputGroup } from 'react-bootstrap';
import DatePicker from "react-multi-date-picker"
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { IoTime } from "react-icons/io5";

const TimePickerComponent = ({ value, onChange }) => {
  return (<>
    <DatePicker 
      value={value}
      disableDayPicker 
      format="HH:mm"
      onChange={(d) => onChange(new Date(d))}
      plugins={[
        <TimePicker position="bottom" hStep={1} mStep={1} hideSeconds/>
      ]}
      containerStyle={{
        width: "100%"
      }}
      render={(v, openCalendar) => {
        return (<>
          <InputGroup>
            <Form.Control onClick={openCalendar} className={`border-end-0`} value={v.toString()}/>
          
            <InputGroup.Text onClick={openCalendar} className='border-start-0 bg-white'>
              <IoTime />
            </InputGroup.Text>
          </InputGroup>
        </>)
      }}
    />
  </>)
}

export default TimePickerComponent