import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Menu, Stack, Typography, Collapse } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import 'moment/locale/zh-hk'

import { FaAngleDoubleRight, FaCamera } from "react-icons/fa";
import { TbCertificate } from "react-icons/tb";

import { GoAlertFill } from "react-icons/go";
import { MdWatch, MdLanguage, MdOutlineLockReset } from "react-icons/md";
import { RiLiveFill } from "react-icons/ri";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { FaTemperatureFull } from "react-icons/fa6";
import { GiProcessor } from "react-icons/gi";
import { FaTools } from "react-icons/fa";
import { MdOutlineAir } from "react-icons/md";
import { MdEditDocument } from "react-icons/md";
import { IoMdWatch } from "react-icons/io";
import { FaLock } from "react-icons/fa6";
import { GrUserWorker } from "react-icons/gr";
import { TbCarCrane } from "react-icons/tb";

import { IconButtonComponent } from 'Shared/Components/Icon';
import useWindowSize from 'Shared/Hooks/useWindowSize';

const DrawerMenu = ({ appbarHeight, drawerWidth, projectInfo }) => {
  const { organization, project } = useParams()
  const { t, i18n } = useTranslation()
  const windowSize = useWindowSize()

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const [subMenuOpen, setSubMenuOpen] = useState();

  const tabs = {
    "cameras": { "text": t("module_cameras"), "icon": <FaCamera size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_cameras_list")},
      {"key": "live", "text": t("module_cameras_live")},
      {"key": "playback", "text": t("module_cameras_playback")},
    ]},
    "ai_detection": { "text": t("module_aiDetection"), "icon": <GiProcessor size={25}/>, "subMenu": [

    ]},
    "proximity": { "text": t("module_proximity"), "icon": <TbCarCrane size={25}/>, "subMenu": [
      // {"key": "list", "text": t("module_proximity_list")},
      {"key": "live", "text": t("module_proximity_live")},
    ]},
    "permit_to_work": { "text": t("module_permitToWork"), "icon": <TbCertificate size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_permitToWork_list")},
      {"key": "templates", "text": t("module_permitToWork_templates")},
    ]},
    "assets_tracking": { "text": t("module_assetsTracking"), "icon": <FaTools size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_assetsTracking_list")},
      {"key": "types", "text": t("module_assetsTracking_types")},
      {"key": "certificates", "text": t("module_assetsTracking_certificates")},
    ]},
    "gas": { "text": t("module_gas"), "icon": <MdOutlineAir size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_gas_data")},
    ]},
    "confined_space_smartwatch": { "text": t("module_confinedSpaceSmartwatch"), "icon": <IoMdWatch size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_confinedSpaceSmartwatch_list")},
      {"key": "devices", "text": t("module_confinedSpaceSmartwatch_devices")},
      {"key": "zones", "text": t("module_confinedSpaceSmartwatch_zones")},
      {"key": "history", "text": t("module_confinedSpaceSmartwatch_history")}
    ]},
    "outdoor_smartwatch": { "text": t("module_outdoorSmartwatch"), "icon": <IoMdWatch size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_outdoorSmartwatch_list")},
    ]},
    "vr_training": { "text": t("module_vrTraining"), "icon": <MdEditDocument size={25}/>, "subMenu": [
      {"key": "records", "text": t("module_vrTraining_records")},
    ]},
    "smartlock": { "text": t("module_smartlock"), "icon": <FaLock size={25}/>, "subMenu": [
      {"key": "list", "text": t("module_smartlock_list")},
    ]},
  }

  const MenuTab = ({ open, icon, route, label, subMenu }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate()
  
    const handleTabClick = () => {
      if (subMenu) {
        setSubMenuOpen(subMenuOpen !== route ? route : null)
      }
      else {
        navigate(`/portal/${organization}/${project}/${route}`)
        setSubMenuOpen()

        if (mobileOpen) {
          setMobileOpen(false)
        }
      }
    }

    const handleSubMenuClick = (k1, k2) => {
      navigate(`/portal/${organization}/${project}/${k1}/${k2}`)

      if (mobileOpen) {
        setMobileOpen(false)
      }
    }

    return (<>
      {(open === true) ? (<>
        <ListItem disablePadding>
          <ListItemButton onClick={handleTabClick}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label}/>

            {subMenu && <>
              {subMenuOpen === route ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </>}
          </ListItemButton>
        </ListItem>

        <Collapse in={subMenuOpen === route} timeout="auto" unmountOnExit>
          <Box style={{ padding: "5px 20px 5px 20px" }}>
            <List component="div" disablePadding style={{ backgroundColor: "#ffffff" }}>
              {subMenu && <>
                {subMenu.map((i) => 
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary={i["text"]} onClick={() => handleSubMenuClick(route, i["key"])}/>
                  </ListItemButton>
                )}
              </>}
            </List>
          </Box>
        </Collapse>
      </>) : (<>
        <ListItem disablePadding sx={{ display: { xs: 'none', lg: 'block' } }}>
          <ListItemButton onClick={handleTabClick}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label}/>

            {subMenu && <>
              {subMenuOpen === route ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </>}
          </ListItemButton>

          <Collapse in={subMenuOpen === route} timeout="auto" unmountOnExit>
            <Box style={{ padding: "5px 20px 5px 20px" }}>
              <List component="div" disablePadding style={{ backgroundColor: "#ffffff" }}>
                {subMenu && <>
                  {subMenu.map((i) => 
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary={i["text"]} onClick={() => handleSubMenuClick(route, i["key"])}/>
                    </ListItemButton>
                  )}
                </>}
              </List>
            </Box>
          </Collapse>
        </ListItem>

        <ListItem disablePadding sx={{ display: { xs: 'flex', lg: 'none' }, justifyContent: "center" }}>
          <IconButtonComponent title={label} placement="right"  onClick={(e) => {
            if (subMenu) {
              setAnchorEl(e.currentTarget)
            }
            else {
              navigate(`/portal/${organization}/${project}/${route}`)
            }
          }}>
            {icon}
          </IconButtonComponent>

          <Menu
            anchorEl={anchorEl}
            open={anchorEl != null}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: "center", horizontal: "right" }}
            transformOrigin={{ vertical: "center", horizontal: "left" }}
          >
            <List component="div" disablePadding>
              {subMenu && <>
                {subMenu.map((i) => 
                  <ListItemButton>
                    <ListItemText primary={i["text"]} onClick={() => handleSubMenuClick(route, i["key"])}/>
                  </ListItemButton>
                )}
              </>}
            </List>
          </Menu>
        </ListItem>
      </>)}
    </>)
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  }
  
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  }

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  }

  return (<>
    <Drawer variant="permanent" open sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box', width: {xs: drawerWidth["xs"], lg: drawerWidth["lg"] } } }}>
      <Box sx={{ display: 'block' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column'}} style={{ height: windowSize["height"] }}>
          <Box sx={{ height: appbarHeight }} />
          <Stack justifyContent="space-between" direction="column" spacing={0} style={{ height: windowSize["height"]-appbarHeight }}>
            <Box style={{ flexGrow: 1, overflow: "auto" }} className="scollbar">
              {projectInfo && 
                <List sx={{ padding: { xs: '5px', lg: '0px' } }}  style={{ backgroundColor: "#ffffff" }}>
                  <MenuTab route="alert" icon={<GoAlertFill size={25} />} label={t("module_alerts")}/>

                  {Object.entries(tabs).map(([k, v]) => <>
                    {(projectInfo["modules"].filter((i) => i["module"] === k && i["enabled"] == 1).length > 0) &&
                      <MenuTab route={k} icon={v["icon"]} label={v["text"]} subMenu={v["subMenu"]}/>
                    }
                  </>)}

                  <MenuTab route="workers" icon={<GrUserWorker size={25} />} label={t("module_workers")}/>
                </List>
              }
            </Box>

            <Divider sx={{ bgcolor: "secondary.light" }}/>

            <Box>
              <ListItem disablePadding sx={{ display: { xs: 'flex', lg: 'none' }, paddingTop: "10px", paddingBottom: "10px", justifyContent: "center" }}>
                <IconButtonComponent title={t("general_settings")} onClick={() => handleDrawerToggle()}>
                  <FaAngleDoubleRight size={20} />
                </IconButtonComponent>
              </ListItem>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Drawer>

    <Drawer variant="temporary" open={mobileOpen} onTransitionEnd={handleDrawerTransitionEnd} onClose={handleDrawerClose} ModalProps={{ keepMounted: true }} sx={{ display: { xs: 'block', lg: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth["lg"] }, zIndex: 2000 }}>
      <Box sx={{ display: 'block' }} >
        <Box sx={{ display: 'flex', flexDirection: 'column'}} style={{ height: windowSize["height"] }}>
          <Box>     
            {projectInfo &&
              <List style={{ padding: "0px" }} >
                <MenuTab route="alert" icon={<GoAlertFill size={25} />} label={t("module_alerts")} open={true}/>

                {projectInfo["modules"].filter((i) => i["enabled"] == 1).map((i) =>
                  <MenuTab route={i["module"]} icon={tabs[i["module"]]["icon"]} label={tabs[i["module"]]["text"]} open={true}  subMenu={tabs[i["module"]]["subMenu"]}/>
                )}

                <MenuTab route="workers" icon={<GrUserWorker size={25} />} label={t("module_workers")} open={true}/>
              </List>
            }
          </Box>
        </Box>
      </Box>
    </Drawer>
  </>)
}

export default DrawerMenu
