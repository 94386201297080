import react, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter, Navigate, Route, Routes, useParams, useLocation } from 'react-router-dom'

import Main from "Pages/Main/Main";
import Dashboard from "Pages/Main/components/Dashboard";
import Alert from "Pages/Alert/Alert";
import Workers from "Pages/Workers/Workers";

import PermitToWorkList from "Pages/PermitToWork/PermitToWorkList";
import PermitToWorkDetail from "Pages/PermitToWork/PermitToWorkDetail";
import PermitToWorkTemplates from "Pages/PermitToWork/PermitToWorkTemplates";

import AssetList from "Pages/AssetsTracking/AssetList";
import AssetTypes from "Pages/AssetsTracking/AssetTypes";
import AssetCertificates from "Pages/AssetsTracking/AssetCertificates";

import AIDetection from "Pages/AIDetection/AIDetection";
import AIDetectionDetail from "Pages/AIDetection/AIDetectionDetail";

import GasData from "Pages/Gas/GasData";

import VRTrainingRecord from "Pages/VRTraining/VRTrainingRecord";

import CameraList from "Pages/Cameras/CameraList";
import CameraLive from "Pages/Cameras/CameraLive";
import CameraPlayback from "Pages/Cameras/CameraPlayback";

import SmartwatchConfinedSpace from "Pages/Smartwatch/ConfinedSpace/SmartwatchConfinedSpace";
import SmartwatchConfinedSpaceZones from "Pages/Smartwatch/ConfinedSpace/SmartwatchConfinedSpaceZones";
import SmartwatchConfinedSpaceHistory from "Pages/Smartwatch/ConfinedSpace/SmartwatchConfinedSpaceHistory";
import SmartwatchConfinedSpaceDevices from "Pages/Smartwatch/ConfinedSpace/SmartwatchConfinedSpaceDevices";
import SmartwatchOutdoor from "Pages/Smartwatch/Outdoor/SmartwatchOutdoor";

import ProximityLive from "Pages/Proximity/ProximityLive";
import ProximityList from "Pages/Proximity/ProximityList";

import SmartlockList from "Pages/Smartlock/SmartlockList";

import { NotifyToast } from 'Shared/Components/NotifyToast'
import { jwtDecode, webUrl } from "Shared/utils";



import React from 'react'
import Signup from "Pages/Main/Signup";
import moment from "moment";
import useLoop from "Shared/Hooks/useLoop";


const Portal = () => {
  const { error, isAuthenticated, isLoading, user, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();

  const [start, setStart] = useState(false)

  useEffect(() => {
    let token = null

    const getToken = async () => {
      token = await getAccessTokenSilently()
      sessionStorage.setItem('accessToken', token)

      const payload = jwtDecode(token)
      const lifetime = payload["exp"] - moment().unix()
    
      setTimeout(async function()  {
        await getToken()
      }, (lifetime - 60)*1000);

      // const token = await getAccessTokenSilently({
      //   organization: auth0OrgId,
      //   ignoreCache: true,
      // })
    }

    const authenticate = async () => {
      try {
        await getToken()
        setStart(true)
      }
      catch (e){
        logout({ logoutParams: { returnTo: webUrl } })
      }
    }

    if (!isLoading) {
      if (!error) {
        if (isAuthenticated) {
          authenticate()
        }
        else {
          loginWithRedirect({
            appState: {
              returnTo: webUrl
            }
          })
        }
      }
      else {
        alert(error)
        logout({ logoutParams: { returnTo: webUrl } })
      }
    }
  }, [isLoading, isAuthenticated])

  return (<>
    {start &&
      <Routes>
        <Route path='/portal/:organization?/:project?' element={<Main />}>
          <Route index element={<Dashboard />} />

          <Route exact path="alert" element={<Alert />} />
          <Route exact path="workers" element={<Workers />} />

          <Route exact path="cameras/list" element={<CameraList />} />
          <Route exact path="cameras/live" element={<CameraLive />} />
          <Route exact path="cameras/playback" element={<CameraPlayback />} />

          <Route exact path="proximity/list" element={<ProximityList />} />
          <Route exact path="proximity/live" element={<ProximityLive />} />

          {/* <Route exact path="ai_detection" element={<AIDetection />} />
          <Route path="ai_detection/:camera_id" element={<AIDetectionDetail />} /> */}

          <Route exact path="permit_to_work/list" element={<PermitToWorkList />} />
          <Route path="permit_to_work/list/:template_id/:form_id?" element={<PermitToWorkDetail />} />
          <Route exact path="permit_to_work/templates" element={<PermitToWorkTemplates />} />

          <Route exact path="assets_tracking/list" element={<AssetList />} />
          <Route exact path="assets_tracking/types" element={<AssetTypes />} />
          <Route exact path="assets_tracking/certificates" element={<AssetCertificates />} />

          <Route exact path="gas/list" element={<GasData />} />

          <Route exact path="vr_training/records" element={<VRTrainingRecord />} />
        
          <Route exact path="confined_space_smartwatch/list" element={<SmartwatchConfinedSpace />} />
          <Route exact path="confined_space_smartwatch/devices" element={<SmartwatchConfinedSpaceDevices />} />
          <Route exact path="confined_space_smartwatch/zones" element={<SmartwatchConfinedSpaceZones />} />
          <Route exact path="confined_space_smartwatch/history" element={<SmartwatchConfinedSpaceHistory />} />

          <Route exact path="outdoor_smartwatch/list" element={<SmartwatchOutdoor />} />

          <Route exact path="smartlock/list" element={<SmartlockList />} />
        </Route>

        <Route index element={<Navigate to="./portal" replace />} />
      </Routes>
    }

    <div>{<NotifyToast/>}</div>
  </>)
}

function App() {
  const location = useLocation()

  if (location.pathname == "/signup") { return <Signup /> }

  return (<Portal />)
}

export default App;
