import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography, Stack } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import moment from 'moment';
import { Form, Input } from 'antd';
import { FaEdit } from "react-icons/fa";

import Floorplan from './components/FloorplanDisplay';
import AlertIcons from '../components/AlertIcons';

import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import PopupModal from 'Shared/Components/PopupModal';
import { SelectField, customizeRequiredMark } from 'Shared/Components/FormComponents'
import { showSuccess } from 'Shared/Components/NotifyToast';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from 'Shared/utils';
import useLoop from 'Shared/Hooks/useLoop';

const SmartwatchConfinedSpaceDevices = () => {
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const [form] = Form.useForm();

  const [data, setData] = useState([])
  const [workers, setWorkers] = useState([])
  const [loading, setLoading] = useState(true);
  const [formOpen, setFormOpen] = useState({ "device": null, "isOpen": false });

  const tableRef = useRef()

  const filterRef = useRef({
    "search": ""
  })

  const loadData = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/devices/confined_space`,
      params: {
        "search": filterRef.current.search
      },
      returnType: "json"
    })

    if (body) {
      setData(body)
    }
  }

  const getWorkers = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/common/workers`,
      returnType: "json"
    })

    body && setWorkers(body)
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        loadData(),
      ]);

      setLoading(false)
    }

    init()    
  }, [])

  const onFinish = async (values) => {
    setLoading(true)

    const res = await send({
      method: "PUT",
      url: `${apiUrl}/smartwatch/assignment`,
      contentType: "json",
      body: {
        "device_id": formOpen["device"],
        "worker_id": values["worker_id"]
      }
    })

    if (res != null) {
      toggleForm()
      showSuccess(t("general_success"))

      tableRef.current.setLoading(true)

      loadData()

      tableRef.current.setLoading(false)
    }

    setLoading(false)
  }

  const toggleForm = async (row) => {
    if (!formOpen["isOpen"]) {
      setLoading(true)
      await getWorkers()

      form.setFieldsValue({
        "worker_id": row["worker_id"]
      })

      setLoading(false)
    }
    else{
      form.resetFields()
    }
    
    setFormOpen({
      "device": row ? row["device_id"] : null,
      "isOpen": !formOpen["isOpen"]
    })
  }

  const onSearch = async (value, _e, info) => {    
    filterRef.current.search = value

    tableRef.current.setLoading(true)

    await loadData()

    tableRef.current.setLoading(false)
  }

  return (<>
    <Container fluid className="mainContainer" style={{ backgroundColor: "white", borderRadius: "15px" }}>
      <Row style={{ height: "100%", marginTop: "5px" }}>
        <Col xs={12} style={{ height: "100%", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ display: "flex", flexFlow: "column", height: "100%" }}>
            <DataTable
              data={data} count={null} ref={tableRef} onSearch={onSearch} searchPlaceHolder={t("smartwatch_searchDevice")}
              // onRowClick={() => console.log("Clicked")}
              columns={{
                "device_id": { "label": t("smartwatch_deviceId"), "sorting": false, "nowrap": true },
                "worker_name": { "label": t("smartwatch_worker"), "sorting": false, "nowrap": true },
              }}
              actions={[
                {icon: <FaEdit size={20}/>, text: "Edit", onClick: function(row) { toggleForm(row) }},
              ]}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <PopupModal isOpen={formOpen["isOpen"]} close toggle={() => toggleForm()} width="80%">
      <Form form={form} layout="vertical" requiredMark={customizeRequiredMark} onFinish={onFinish} initialValues={{ }} style={{ width: "100%", padding: "10px" }}>      
        <SelectField field_name={t("smartwatch_worker")} field_id="worker_id" props={{ "type": "dropdown", "options": workers.map((i) => ({ "value": i["worker_id"], "label": i["worker_name"] })) }}/>

        <ButtonGroup style={{ width: "100%" }}>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </ButtonGroup>
      </Form>
    </PopupModal>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default SmartwatchConfinedSpaceDevices